import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const ADHDTreatment = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">ADHD</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                        style={{ marginLeft: 10, marginRight: 10 }}
                                        icon={faAngleRight} />
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                        style={{ marginLeft: 10, marginRight: 10 }} icon={faAngleRight} />
                                    <span>ADHD</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Børn og unge med ADHD/ADD</h5>
                                        <p>
                                            ADHD (Attention Deficit Hyperactivity Disorder) er en udviklingsforstyrrelse, der påvirker en persons opmærksomhed, impulskontrol og hyperaktivitet. Børn med ADHD kan have svært ved at fokusere, være impulsive og have problemer med at sidde stille. Det kan også være vanskeligt at skabe overblik og have mange ”bolde i luften”.<br />
                                            ADD (Attention Deficit Disorder) er ligeledes en udviklingsforstyrrelse, der påvirker en persons opmærksomhed og impulskontrol, men ved ADD ses ingen hyperaktivitet. Udover at have svært ved at fokusere og styre sine impulser, kan børn med ADD være tilbøjelige til at dagdrømme og generelt have svært ved at organisere og skabe struktur i hverdagen.
                                        </p>
                                        <p>
                                            Er du i tvivl, om dit barn har ADHD/ADD, så kontakt mig gerne for at høre om mulighederne for en udredning.
                                        </p>
                                        <p>
                                            Såvel ADHD og ADD kan have en negativ indvirkning på barnets sociale interaktioner, skolegang og generelle velbefindende. Det er derfor vigtigt, at man som forældre lærer, hvordan man på bedst mulig vis hjælper sit barn med ADHD/ADD. Måske har dit barn allerede fået stillet diagnosen, måske er barnet i gang med en udredning eller måske oplever I symptomer hos jeres barn, der kan ligne ADHD/ADD? <br />
                                        </p>
                                        <p>
                                            Jeg har sammensat et forældrevejledningsforløb på 4 sessioner, hvor vi arbejder ud fra bestemte emner med dit barn i centrum. I forløbet får du redskaber til at være forældre til et barn med ADHD/ADD eller lignende symptomer. Barnet deltager som udgangspunkt ikke i forløbet.<br/>
                                            Kontakt mig gerne for at høre nærmere.
                                        </p>
                                    </div>
                                </div>
                                <BookAppointment />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default ADHDTreatment;