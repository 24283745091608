import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const About = () => {
    return <>

        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Nina Kristensen</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a> <FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <span>Nina Kristensen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        
                                        <div className="imgWrapperText">
                                            <img style={{maxHeight: "350px"}} src="/img/nina-kristensen-portrait.jpg" alt=""/>
                                        </div>

                                        <div className={"content"}>
                                            <p>Jeg er uddannet psykolog fra Aarhus Universitet i 2013 og blev autoriseret af Psykolognævnet i 2015. Som autoriseret psykolog er jeg underlagt Psykologloven og har tavshedspligt. Jeg er medlem af Dansk Psykologforening.</p>
                                            <p>Mit primære fokus er at hjælpe børn, unge og familier med psykologiske problemstillinger. Det kan være problemstillinger om alle de ting, der rører sig, når man er barn eller ung og indgår i en familie.</p>
                                            <p>For mig er det vigtigt, at du føler dig set og hørt. Jeg vil bestræbe mig på at skabe et trygt og tillidsfuldt terapeutisk rum, hvor du kan føle dig godt tilpas. I mødet med dit barn vil det samme naturligvis gøre sig gældende.</p>
                                            <p>Jeg anvender hovedsageligt kognitiv adfærdsterapi men er også inspireret af andre terapiformer såsom systemisk terapi, narrativ terapi og metakognitiv terapi.</p>
                                            <p>Faglig og professionel kvalitet er for mig meget vigtigt, og jeg modtager derfor løbende supervision.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="contentPadding grey">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="titleShortcode">
                            <h4 className="h4 as">Erfaring</h4>
                        </div>
                        <div className="simple-article normall">
                            <p>
                                <b>Psykolog</b><br/>
                                Pædagogisk Psykologisk Rådgivning og Specialpædagogik, Aarhus Kommune <br/>
                                2016 - 2023
                            </p>
                            <p>
                                <b>Psykolog</b><br/>
                                Pædagogisk Psykologisk Rådgivning, Randers Kommune<br/>
                                2013 - 2016
                            </p>
                            <p>
                                <b>Forskningsassistent</b><br/>
                                Center for Psykologisk Behandling til Børn og Unge<br/>
                                2012 - 2013
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                    </div>
                </div>
            </div>
        </div>
        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="titleShortcode">
                            <h4 className="h4 as">Udannelse</h4>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <div className="year">
                            <span>2022</span>
                            <div className="yearContent">
                                <h5 className="h5 as">Certificeret Cool Kids / Chilled behandler</h5>
                            </div>
                        </div>
                        <div className="emptySpace25"></div>
                        <div className="year">
                            <span>2021</span>
                            <div className="yearContent">
                                <h5 className="h5 as">Første del af specialistuddannelsen i Klinisk Børne- og Ungepsykologi</h5>
                            </div>
                        </div>
                        <div className="emptySpace25"></div>
                        <div className="year">
                            <span>2016</span>
                            <div className="yearContent">
                                <h5 className="h5 as">Autorisation</h5>
                            </div>
                        </div>
                        <div className="emptySpace25"></div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <div className="year">
                            <span>2013</span>
                            <div className="yearContent">
                                <h5 className="h5 as">Master's degree, Psychology</h5>                                
                            </div>
                        </div>
                        <div className="emptySpace25"></div>
                        <div className="year">
                            <span>2012</span>
                            <div className="yearContent">
                                <h5 className="h5 as">Uddannelsesforløb hos Center for Psykologisk Behandling til Børn og Unge</h5>
                            </div>
                        </div>
                        <div className="emptySpace25"></div>
                        <div className="year">
                            <span>2010</span>
                            <div className="yearContent">
                                <h5 className="h5 as">Bachelor's degree, Psychology</h5>
                            </div>
                        </div>
                        <div className="emptySpace25"></div>
                    </div>
                </div>
            </div>
        </div>


    </>
        ;
};

export default About;