import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const FamilyChallenges = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Udfordringer i familien</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Udfordringer i familien</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Udfordringer i familien</h5>
                                        <p>
                                            Oplever du udfordringer i familielivet? At opleve udfordringer i familielivet er helt naturligt, men nogle gange kan udfordringerne blive så store, at man som forældre føler sig rådvild og magtesløs. Det kan være, at du som forælder oplever at have forskellige tilgang til opdragelse i forhold til barnets anden forælder, at hverdagen er blevet uoverskuelig eller at der er opstået ændringer i jeres familiestruktur. Jeg vil gerne hjælpe jer til at få et mere roligt og afbalanceret hverdagsliv.
                                        </p>

                                    </div>
                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default FamilyChallenges;