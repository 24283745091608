import React, {useState} from "react";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MenuItem = ({text, children, open}) => {
    return <>
        <a href="#" >{text}</a>
        <i className={"fa"}>
            { open && <FontAwesomeIcon icon={faAngleUp}/>}
            { !open && <FontAwesomeIcon icon={faAngleDown}/>}
        </i>
        <ul style={(open ? {display: "block"} : {})}>
            {children}
        </ul>
    </>
}
export default MenuItem;

