import ImageThumb from "../components/ImageThumb/ImageThumb";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

const Assessments = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Undersøgelse</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a> <FontAwesomeIcon icon={faAngleRight}/>
                                    <span> undersøgelse</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row clearFix big">
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Intelligenstests"} imageUrl={"img/tumbs/intelligencetest-1.jpg"}
                                    link={"/undersoegelser/intelligenstests"}>
                            Jeg intelligenstestning af børn og unge i alderen 6-16 år.
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Udredning"} imageUrl={"img/tumbs/assesment-1.jpg"}
                                    link={"/undersoegelser/udredning"}>
                            Jeg tilbyder udredning af børn og unge i alderen 6-16 år.
                        </ImageThumb>
                    </div>
                </div>
            </div>
        </div>
    </>;


};

export default Assessments;