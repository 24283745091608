import 'photoswipe/dist/photoswipe.css'
import { faAngleRight, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Gallery, Item } from "react-photoswipe-gallery";
const AboutTheClinic = () => {
    return <>

        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Klinikken</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a> <FontAwesomeIcon
                                        style={{ marginLeft: 10, marginRight: 10 }}
                                        icon={faAngleRight} />
                                    <span>Klinikken</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contentPadding">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="simple-article normall">
                            <h2 class="h2 as">Klinikken</h2>

                            <Gallery>
                                <div class="galleryContainer clearFixGalley normall">
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG1.jpg"
                                            thumbnail="img/office/IMG1.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG1.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG4.jpg"
                                            thumbnail="img/office/IMG4.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG4.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG5.jpg"
                                            thumbnail="img/office/IMG5.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG5.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG6.jpg"
                                            thumbnail="img/office/IMG6.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG6.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG7.jpg"
                                            thumbnail="img/office/IMG7.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG7.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG8.jpg"
                                            thumbnail="img/office/IMG8.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG8.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG9.jpg"
                                            thumbnail="img/office/IMG9.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG9.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG10.jpg"
                                            thumbnail="img/office/IMG10.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG10.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG11.jpg"
                                            thumbnail="img/office/IMG11.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG11.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33"></div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG12.jpg"
                                            thumbnail="img/office/IMG12.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG12.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                    <div class="my-col-33">
                                        <Item
                                            original="img/office/IMG13.jpg"
                                            thumbnail="img/office/IMG13.jpg"
                                            width="1024"
                                            height="768"
                                        >
                                            {({ ref, open }) => (
                                                <div className='imgWrapper imgTumb lightbox' ref={ref} onClick={open}>
                                                    <img width={380} height={320}  src="img/office/IMG13.jpg"/>
                                                </div>
                                            )}
                                        </Item>
                                    </div>
                                </div>
                            </Gallery>




                        </div>
                        <div class="emptySpace50 emptySpace-xs30"></div>
                    </div>
                </div>
            </div>
        </div>
    </>
        ;
};

export default AboutTheClinic;