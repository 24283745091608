import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import Treatments from "../treatments.json"
import {useEffect, useState} from "react";
import Accordion from "../components/Accordion/Accordion";
import BookAppointment from "../components/BookAppointment/BookAppointment";

const Prices = () => {

    return <>
        <div className="services-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Priser</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a> <FontAwesomeIcon style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>priser</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-md-push-8 col-lg-3 col-lg-push-9">
                        <aside className="blogAside">
                            <div className="openingHours">
                                <h6 className="h6 as">Åbningstider</h6>
                                <ul className="normall">
                                    <li><span>Mandag</span> <span>8.30 - 15.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Tirsdag</span> <span>8.30 - 16.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Onsdag</span> <span>8.30 - 15.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Torsdag</span> <span>8.30 - 15.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Fredag</span> <span>8.30 - 14.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Lørdag</span> <span>Lukket</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Søndag</span> <span>Lukket</span>
                                        <div className="clear"></div>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>
                    <div className="col-sm-12 col-md-8 col-md-pull-4 col-lg-9 col-lg-pull-3">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <p>
                                            En konsultationer varer 50 minutter medmindre andet er aftalt. Konsultationerne finder som udgangspunkt sted i min klinik i Hinnerup, men de kan også afholdes online, telefonisk eller i dit hjem.
                                        </p>
                                        <div>
                                            <table className={"table table-condensed"}>
                                                <thead>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{width: "180px", textAlign: "right"}}></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Indledende konsultation (75 min.)</td>
                                                        <td className="text-right">1575,00 kr</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Individuel konsultation</td>
                                                        <td className="text-right">1050,00 kr</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Familiekonsultation</td>
                                                        <td className="text-right">1050,00 kr</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Telefon-/onlinekonsultation</td>
                                                        <td className="text-right">1050,00 kr</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Supervision (60 min.)</td>
                                                        <td className="text-right">1100,00 kr</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deltagelse i netværksmøde</td>
                                                        <td className="text-right">fra 1575,00 kr</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Intelligenstest (7-16 år)</td>
                                                        <td className="text-right">Se <a href="/undersoegelser/intelligenstests#pakker_priser">Pakker og priser</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Tillæg for transport: 850 kr. per time + 3,70 kr. per kørt KM.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <br/>
                                        
                                        <h4 className="h4 as">Betaling</h4>
                                        <p>
                                            Betaling sker efter hver konsultation via bankoverførsel eller Mobilepay.
                                        </p>
                                        <h4 className="h4 as">Tilskud</h4>
                                        <div className="accordeon normall">
                                            <Accordion headerText={"Sygeforsikringen Danmark"}>
                                                <p>
                                                    Er du medlem af Sygeforsikringen Danmark i gruppe 1, 2 eller 5, kan du være berettiget til et tilskud på op til 300 kr. pr. konsultation. Du betaler den fulde pris hos mig, hvorefter jeg indberetter regningen hos Sygeforsikringen Danmark. Sygeforsikringen Danmark vil efterfølgende udbetale dit tilskud direkte til dig.
                                                    Du kan læse mere om tilskud fra Sygeforsikringen Danmark og henvisningsårsager på <a href="www.sygeforsikring.dk">www.sygeforsikring.dk</a>
                                                </p>
                                            </Accordion>
                                            <Accordion headerText={"Private sundhedsforsikringer"}>
                                                <p>Hvis du har en privat sundhedsforsikring, kan du undersøge, om din forsikring dækker psykologkonsultationer eller giver tilskud hertil. Selvom forsikringsselskaberne nogle gange har deres egne psykologer tilknyttede, kan du benytte dig af muligheden for ”selvvalgt psykolog”. Bevillingen fra dit forsikringsselskab kan du derefter sende til mig via sikker mail.</p>
                                            </Accordion>
                                            <Accordion headerText={"Forsikringsselskaber"}>
                                                <p>Flere pensionskasser og forsikringsselskaber dækker helt eller delvist de økonomiske omkostninger for psykologbehandling.</p>
                                            </Accordion>
                                        </div>
                                        <div className="emptySpace40 emptySpace-xs30"></div>
                                        <h4 className="h4 as">Afbud</h4>
                                        <p>
                                            Afbud eller ændringer bedes foretaget hurtigst muligt og senest 24 timer før din aftale. Ved afbud eller ændring bedes du ringe eller skrive en sms på telefonnr. <a href="tel:71901586">71 90 15 86</a>. 
                                            Ved udeblivelse eller for sen aflysning opkræves fuldt honorar for den aftalte konsultationstid.
                                        </p>
                                    </div>
                                </div>
                               <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default Prices;