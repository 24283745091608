import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const DistressTreatment = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Mistrivsel</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Mistrivsel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Mistrivsel hos børn og unge</h5>
                                        <p>
                                            Oplever du, at dit barn er i mistrivsel? Som forældre mærker vi som regel hurtigt, hvis vores børn ikke trives. Det kan være, at barnet ikke vil i skole, at barnet trækker sig fra sine venner, at barnet reagerer med voldsom vrede i hjemmet eller noget helt fjerde. Hvis du har svært ved at finde ud af, hvad årsagen til dit barns reaktioner er og har brug for viden om, hvordan du bedst hjælper dit barn, så er du velkommen hos mig.
                                        </p>
                                    </div>
                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default DistressTreatment;