import React, {useEffect, useState} from "react";
import $ from 'jquery';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import NoPage from "./pages/NoPage";
import Main from "./pages/Main";
import Assessments from "./pages/Assessments";
import Prices from "./pages/Prices";
import IntelligenceTests from "./pages/Intelligencetests";
import TherapeuticApproach from "./pages/TherapeuticApproach";
import AboutTheClinic from './pages/AboutTheClinic';
import Training from './pages/Training';
import Supervision from './pages/Supervision';
import Assesment from './pages/Assesment';
import Contact from './pages/Contact';
import AnxietyTreatment from './pages/treatment/AnxietyTreatment';
import ADHDTreatment from './pages/treatment/ADHDTreatment';
import AutismTreatment from './pages/treatment/AutismTreatment';
import DepressionTreatment from './pages/treatment/DepressionTreatment';
import DistressTreatment from './pages/treatment/DistressTreatment';
import LonelinessTreatment from './pages/treatment/LonelinessTreatment';
import Coolkids from './pages/treatment/Coolkids';
import Groups from './pages/treatment/Groups';
import FamilyChallenges from './pages/treatment/FamilyChallenges';
import Testimonials from './pages/Testimonials'
import CoolkidsForloeb from './pages/CoolkidsForloeb';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-CTFW0MHT5N";

class App extends React.Component {
   
    componentDidMount() {// TODO FIX THIS
        const winW = $(window).width();
		const winH = $(window).height();
		if ( winW > 992 ) {
            $('.headerClearFix').css('height', $('.headerTopInfo').outerHeight() );
		} else {
            $('.headerClearFix').css('height', $('header').outerHeight() );
		}
		$('.navScroll').css('max-height', winH - 60 );
        
        ReactGA.initialize(TRACKING_ID);
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });    
    }
    
    render(){
        return (
                <BrowserRouter>
                    <Routes>
                        <Route path={"/"} element={<Main/>}>
                            <Route index={true} element={<Home/>}/>
    
                            <Route path="behandlinger">
                                <Route index={true} element={<Services/>}></Route>
                                <Route path="angst" element={<AnxietyTreatment/>}/>
                                <Route path="coolkids" element={<Coolkids/>}/>
                                <Route path="adhd" element={<ADHDTreatment/>}/>
                                <Route path="autisme" element={<AutismTreatment/>}/>
                                <Route path="depression" element={<DepressionTreatment/>}/>
                                <Route path="mistrivsel" element={<DistressTreatment/>}/>
                                <Route path="ensomhed" element={<LonelinessTreatment/>}/>
                                <Route path="udfordringerifamilien" element={<FamilyChallenges/>}/>                                
                                <Route path="gruppeforloeb" element={<Groups/>}/>
                            </Route>
                           
                            <Route path="undersoegelser">
                                <Route index={true} element={<Assessments/>}></Route>
                                <Route path="intelligenstests" element={<IntelligenceTests/>}></Route>
                                <Route path="udredning" element={<Assesment/>}></Route>
                            </Route>

                            <Route path="forloeb">
                                <Route path="coolkids" element={<CoolkidsForloeb/>}/>
                            </Route>

                            <Route path="kontakt" element={<Contact/>}/>
                            <Route path="priser" element={<Prices/>}/>
                            <Route path="terapeutisk-tilgang" element={<TherapeuticApproach/>}/>
                            <Route path="omkring-mig" element={<About/>}/>
                            <Route path="omkring-klinikken" element={<AboutTheClinic/>}/>
                            
                            <Route path="foredrag" element={<Training/>}/>
                            <Route path="supervision" element={<Supervision/>}/>

                            <Route path="anbefalinger" element={<Testimonials/>}/>
                            
                            <Route path="*" element={<NoPage/>}/>

                        </Route>
                    </Routes>
                </BrowserRouter>

        );
    }
}

export default App;
