import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const AutismTreatment = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Autisme</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Autisme</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Børn og unge med autisme</h5>
                                        <p>                                        
                                            Autisme, også kendt som autismespektrumforstyrrelse (ASF), er en udviklingsforstyrrelse, der påvirker kommunikation og social interaktion. Børn med ASF kan have svært ved at fortolke og reagere på sociale koder, og de kan have gentagne adfærdsmønstre eller interesser. ASF kan være mild til svær grad og kan være forskelligt præsenteret hos hver enkelt person.
                                            Forældre og omsorgspersoner spiller en afgørende rolle i at støtte udviklingen af børn med ASF. Det er vigtigt at tilbyde et støttende og struktureret miljø samt at tilegne sig viden om diagnosen og om, hvordan man bedst støtter barnets udvikling. Jeg har sammensat et forældrevejledningsforløb på 4 sessioner, hvor vi arbejder ud fra bestemte emner med dit barn i centrum. I forløbet får du redskaber til at være forældre til et barn med autisme. Barnet deltager som udgangspunkt ikke i forløbet.
                                            Kontakt mig for at høre nærmere.
                                        </p>
                                        <p>
                                            Er du ung og har autisme, har du måske mange spørgsmål i forhold til, hvordan du skal forstå dig selv og andre, eller hvordan du får et godt liv. Jeg vil meget gerne hjælpe dig med de spørgsmål.
                                            Kontakt mig for at høre nærmere.
                                        </p>
                                        <p>
                                            Er du i tvivl, om dit barn har autisme, så kontakt mig venligst for at høre om mulighederne for en udredning.
                                        </p>
                                    </div>
                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default AutismTreatment;