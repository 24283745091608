import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const LonelinessTreatment = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Ensomhed</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Ensomhed</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Ensomhed hos børn og unge</h5>
                                        <p>
                                            Ensomhed blandt unge er desværre et stigende problem i vores samfund. Mange unge oplever følelsen af at være isoleret og alene, selvom de måske har en stor vennekreds.Årsagerne til ensomhed blandt unge kan være mange og forskellige. Nogle unge oplever ensomhed på grund af mangel på sociale færdigheder eller selvtillid, mens andre kan have svært ved at finde ligesindede mennesker at dele interesser med. Ensomhed haren negativ effekt på den mentale og fysiske sundhed. Det kan føre til depression, angst og andre mentale sundhedsproblemer. Det er derfor vigtigt at få hjælp, hvis dit barn eller du som ung oplever ensomhed.
                                        </p>
                                        <p>
                                            Hos mig kan du få hjælp til at tackle følelsen af ensomhed, ligesom vi sammen kan gå på opdagelse i, hvad der ligger til grund for ensomheden, og hvad man kan gøre for at komme ud af ensomheden. Kontakt mig gerne for at høre nærmere eller se det <a href="/behandlinger/gruppeforloeb">Gruppeforløb</a>, jeg udbyder for ensomme unge.
                                        </p>

                                    </div>
                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default LonelinessTreatment;