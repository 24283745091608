import {useState} from "react";

const Accordion = ({headerText, children}) => {
    const [open, setOpen] = useState(false);

    return <>
        <div className={open ? "accordeon-title active" : "accordeon-title"} onClick={() => setOpen(!open)}>
            <div className="accrodeonButton"><span></span><span></span></div>
            {headerText}
        </div>
        <div className="accordeon-toggle" style={{display: open ? 'block' : 'none'}}>
            <div className="simple-article">
                <p>{children}</p>
            </div>
        </div>
        <div className="emptySpace20"></div>
    </>
}
export default Accordion;

