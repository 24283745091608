import { faAngleRight, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookAppointment from "../components/BookAppointment/BookAppointment";

const Contact = () => {
    return <>

        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Kontakt</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a> <FontAwesomeIcon
                                        style={{ marginLeft: 10, marginRight: 10 }}
                                        icon={faAngleRight} />
                                    <span>Kontakt</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="contentPadding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-8">
                        <div class="simple-article normall">
                            <h5 class="h5">Kontakt</h5>
                            <p>Du er velkommen til at skrive en mail til mig. Skriv gerne i mailen et tidspunkt på dagen, hvor det vil passe dig, at jeg kontakter dig. Så ringer jeg så vidt muligt tilbage inden for dette tidsrum.</p>
                            <p>Du kan også ringe på telefon alle hverdage. Læg gerne en besked på telefonsvareren, så ringer jeg tilbage hurtigst muligt.</p>

                            <div class="emptySpace50 emptySpace-xs30"></div>
                            <h4 class="h4">Klinikken</h4>
                            <p>
                                Fanøvej 10E, 8382 Hinnerup
                            </p><br/>
							<p>Der er gode forbindelser med både bil, bus og tog til klinikken, som ligger tæt på motorvejen.</p>
							<p>Nærmeste busstoppested ligger 5 minutters gang fra klinikken, og her holder bus 111 (Sabro), bus 115 (Hadsten, Langå) og bus 200 (Skanderborg, Hørning). Klinikken ligger 20 minutters gang fra Hinnerup togstation.</p>
							<p>Der er gode og gratis parkeringsforhold lige udenfor klinikken.</p>
                            
                            <div style={{textAlign: "center", marginTop: "40px"}}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2216.615645963767!2d10.072477216061099!3d56.250149360990676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c16ada9434d81%3A0xa3f1887787ba2a00!2sFan%C3%B8vej%2010E%2C%208382%20Hinnerup!5e0!3m2!1sda!2sdk!4v1675606786616!5m2!1sda!2sdk" 
                                width="100%" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>
                        <div class="emptySpace50 emptySpace-xs30"></div>

                        
                        <div style={{ marginTop: "20px" }}>
                            <BookAppointment />
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <aside className="" style={{ marginTop: 10 }}>
                            <div className="openingHours">
                                <h6 className="h6 as">Åbningstider</h6>
                                <ul className="normall">
                                    <li><span>Mandag</span> <span>8.30 - 15.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Tirsdag</span> <span>8.30 - 16.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Onsdag</span> <span>8.30 - 15.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Torsdag</span> <span>8.30 - 15.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Fredag</span> <span>8.30 - 14.00</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Lørdag</span> <span>Lukket</span>
                                        <div className="clear"></div>
                                    </li>
                                    <li><span>Søndag</span> <span>Lukket</span>
                                        <div className="clear"></div>
                                    </li>
                                </ul>
                            </div>
                        </aside>

                        <div className="strip strip5" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="single-content">
                                            <a href="tel:71901586">
                                                <div className="icon">
                                                    <i className="flaticon1-smartphone-call"></i>
                                                </div>
                                                <div className="text">
                                                    <h4>Telefon</h4>
                                                    <p className="font-24">71 90 15 86</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="single-content">
                                            <a href="mailto:mail@nina-kristensen.dk">
                                                <div className="icon">
                                                    <i className="flaticon1-envelope fi-rr-envelope"></i>
                                                </div>
                                                <div className="text">
                                                    <h4>send en email</h4>
                                                    <p>mail@nina-kristensen.dk</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    </>
        ;
};

export default Contact;