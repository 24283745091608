import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const AnxietyTreatment = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Angst</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Angst</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Angst hos børn og unge</h5>
                                        <p>Angst er en helt normal reaktion, som vi alle kommer til at opleve på et eller andet tidspunkt i vores liv. Nogle
                                            gange kan angsten dog komme til at fylde så meget, at den forhindrer os i at gøre noget, vi rigtig gerne vil. I de
                                            tilfælde er det vigtigt at lære, hvordan man håndterer følelsen af angst på en sund måde.</p>
                                        <p> Hos børn og unge kan angst se ud på mange forskellige måder. Her er nogle eksempler:

                                        <div className="simple-article-text-box-wrapper">
                                            <ul className="simple-article-bullets split-list">
                                                <li>Angst for at komme i skole</li>
                                                <li>Angst for at være sammen med mange mennesker</li>
                                                <li>Angst for bestemte dyr eller ting</li>
                                                <li>Angst for at kaste op</li>
                                                <li>Angst for at sove i egen seng</li>
                                                <li>Angst for at være adskilt fra forældre</li>
                                                <li>Angst for hvad andre tænker om én</li>
                                                <li>Angst for nye og ukendte situationer</li>
                                                <li>Generel stor bekymringstendens</li>
                                                <li>Tvangstanker og tvangshandlinger (OCD)</li>
                                                <li>Ubehag i kroppen uden at der er nogen fysiologisk grund</li>
                                            </ul>
                                        </div>
                                        </p>
                                        <p> Jeg er certificeret Cool Kids/Chilled behander og har arbejdet med ængstelige børn og unge i over 10 år. Hvis du oplever
                                            ængstelige tanker hos dit barn, er du velkommen til at kontakte mig for at få hjælp. <a href="/behandlinger/coolkids">Se mere her.</a> </p>
                                        
                                        <br/>
                                        <b style={{color: "#242424"}}>Eksamensangst</b>
                                        <p>Eksamensangst er en form for angst eller nervøsitet, som kan opstå i forbindelse med at skulle tage en eksamen eller prøve. Det er en almindelig tilstand, som mange studerende oplever på et eller andet tidspunkt i deres uddannelse.</p>
                                        <p>Eksamensangst kan manifestere sig på forskellige måder, herunder fysiske symptomer såsom hjertebanken, svedige hænder og kvalme, samt mentale symptomer såsom bekymring, negative tanker og følelsen af at være overvældet eller fastlåst.</p>
                                        <p>Angsten kan have en negativ effekt på en persons præstation, da den kan påvirke ens evne til at tænke klart, huske information og besvare spørgsmål korrekt. Derfor er det vigtigt at lære at håndtere og reducere eksamensangst, så man kan udføre sit bedste i eksamenssituationen. Hvis du har brug for hjælp til dette, er du velkommen til at kontakte mig.</p>
                                    </div>
                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default AnxietyTreatment;