import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const Groups = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Gruppeforløb</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Gruppeforløb</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Gruppeforløb</h5>
                                        <p>
                                            I perioder udbyder jeg følgende gruppeforløb:
                                        </p>
                                        <p>
                                            <b><a href="/behandlinger/coolkids">Cool Kids/Chilled</a></b><br/>
                                            Cool Kids/Chilled er et angstbehandlingsforløb til børn og unge i alderen 7-12 år (Cool Kids) og 13-17 år (Chilled). Forældrene deltager også i behandlingen.
                                        </p>
                                        <p>
                                            <b>Ensomhed hos unge</b><br />
                                            Jeg er i gang med at udarbejde et gruppeforløb til unge, der kæmper med ensomhed. Grupperne kommer til at bestå af 3-5 unge, der alle har det tilfælles, at de føler sig ensomme. Forløbet kommer til at omhandle, hvordan man som ung kan tackle ensomhed, og der kommer til at være konkrete øvelser i social færdighedstræning, det vil sige, hvordan man er sammen med andre mennesker.<br/>
                                            Hvis du oplever ensomhed og ønsker et individuelt forløb se <a href="behandlinger/ensomhed">her.</a>
                                        </p>
                                        <p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BookAppointment />
            </div>
        </div>

    </>;
}
export default Groups;