import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../components/BookAppointment/BookAppointment";

const CoolkidsForloeb = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Cool kids forløb 2024</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                        style={{ marginLeft: 10, marginRight: 10 }}
                                        icon={faAngleRight} />
                                    <span>Cool kids forløb 2024</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                    <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Cool Kids forløb 2024</h5>
                                        <p>Torsdag den 3. oktober 2024 starter et nyt Cool Kids forløb i Hinnerup torsdage kl. 15-17</p>
                                        <p>
                                            Er dit barn mellem 9 og 12 år og kæmper med angst? Jeg tilbyder det manual- og evidensbaseret gruppebehandlingsforløb Cool Kids til børn med angst og deres forældre. <br/> 
                                            Cool Kids har været anvendt i Danmark i mere end 10 år til behandling af angst hos børn og med rigtig gode resultater. Det er et intensivt gruppeforløb bestående af 10 sessioner a 2 timers varighed.
                                        </p>
                                        <p>Formålet med programmet er at lære jer og jeres barn strategier til at håndtere angst og bekymringer, så angsten og bekymringerne ikke forhindrer jeres barn i at gøre det, barnet har lyst til og som er godt for barnet. Ved behandlingsstart udleveres et Cool Kids arbejdshæfte til henholdsvis forældre og barn. </p>

                                        <p>Læs mere om angst og Cool Kids <a href="/behandlinger/coolkids">her.</a></p>


                                        <p>Nedenfor er tidspunkterne for sessionerne:
                                        <div className="simple-article-text-box-wrapper">
                                        <table className={"table table-condensed"}>
                                            <thead>
                                                <tr>
                                                    <td>Dato</td>
                                                    <td>Session</td>
                                                    <td>Emne</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Efter aftale</td>
                                                    <td>Indledende samtale</td>
                                                    <td>Samtale med forældre og barn om angst og trivsel</td>
                                                </tr>
                                                <tr>
                                                    <td>3. oktober</td>
                                                    <td>1. session</td>
                                                    <td>Velkomst, viden om angst, bekymringsskala, belønning og mål</td>
                                                </tr>
                                                <tr>
                                                    <td>10. oktober</td>
                                                    <td>2. session</td>
                                                    <td>Fortsættelse af mål, tankefejl og detektivtænkning</td>
                                                </tr>
                                                <tr>
                                                    <td>17. oktober</td>
                                                    <td>Efterårsferie</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>24. oktober</td>
                                                    <td>3. session</td>
                                                    <td>Detektivtænkning og forældreadfærd</td>
                                                </tr>
                                                <tr>
                                                    <td>30. oktober ONSDAG</td>
                                                    <td>4. session</td>
                                                    <td>Trappestiger</td>
                                                </tr>
                                                <tr>
                                                    <td>7. november</td>
                                                    <td>Øveuge/fri</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>14. november</td>
                                                    <td>5. session</td>
                                                    <td>Kreative trappestiger og eksponering</td>
                                                </tr>
                                                <tr>
                                                    <td>21. november</td>
                                                    <td>6. session</td>
                                                    <td>Afledning, afslappende teknikker og problemløsning</td>
                                                </tr>
                                                <tr>
                                                    <td>28. november</td>
                                                    <td>7. session</td>
                                                    <td>Social færdighedstræning og forældreadfærd</td>
                                                </tr>
                                                <tr>
                                                    <td>5. december</td>
                                                    <td>8. session</td>
                                                    <td>Eksponering</td>
                                                </tr>
                                                <tr>
                                                    <td>12. december</td>
                                                    <td>9. session</td>
                                                    <td>Fejring og afslutning</td>
                                                </tr>
                                                <tr>
                                                    <td>30. januar 2025</td>
                                                    <td>10. session</td>
                                                    <td>Opfølgning</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        </p>
                                        <p>Gruppen består af ca. 4 børn og deres forældre/voksne. Det mest optimale er at være to voksne pr. barn. Hvis det ikke er muligt for begge forældre at deltage, er en anden voksen, som står barnet nært, meget velkommen.</p>

                                        <p>I hver session starter vi sammen alle sammen med velkomst og opfølgning. Efterfølgende undervises og øves der med børnene i cirka en time, imens forældrene læser i deres arbejdshæfter og taler med de andre forældre om relevante emner. Bagefter undervises og øves forældrene, imens børnene hygger, spiller spil eller lign.</p>


                                        <h6>Indledende samtale</h6>
                                        <p>Inden I tilbydes en plads i forløbet, foretages en indledende samtale med jer og jeres barn for at screene for angst samt vurdere, om I vil få udbytte af forløbet.</p>
                                        <p>Samtalen varer ca. 2 time og har følgende indhold:
                                        <div className="simple-article-text-box-wrapper">
                                            <ul className="simple-article-bullets">
                                                <li>Velkomst og introduktion</li>
                                                <li>Samtale med jer forældre, imens jeres barn venter i venteværelset</li>
                                                <li>Samtale med jeres barn, imens I venter i venteværelset</li>
                                                <li>Vi afslutter sammen, og I får udleveret relevant materiale</li>
                                            </ul>
                                        </div>
                                        </p>
                                        <h6>Pris</h6>
                                        <p>
                                            Forløb på 10 sessioner a 2 timers varighed: 10.000 kr. + indledende samtale a ca. 2 timers varighed: 2100 kr.
                                            <br/>
                                            Kontakt gerne din sundhedsforsikring og hør, om de dækker omkostningerne til et Cool Kids forløb.
                                        </p>

                                        <div>
                                            <img style={{ maxWidth: "500px", margin: "auto" }} src="/img/coolkidscertificeret-CEBU-logo-farve.png" />
                                        </div>
                                    </div>





                                </div>
                                <BookAppointment />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default CoolkidsForloeb;