import React from 'react';
import SlideShow from "../components/SlideShow/SlideShow";
import {Swiper, SwiperSlide} from "swiper/react";
import Treatments from "../treatments.json"
import Accordion from '../components/Accordion/Accordion';


const Home = () => {

    return <>      
        <div className="welcome_consulting">
            <div className="contentPadding no-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <img alt="" src="img/nina-kristensen-2.png" className="front-page-psycologist-img"/>
                        </div>

                        <div className="col-md-7">
                            <div className="contentTitle normall">
                                <h2 className="h2 as font-36">NINA KRISTENSEN<br/>
                                Autoriseret psykolog
                                </h2>
                                <h2 className="h2 as font-36"></h2>
                                <h3 style={{"fontSize": "26px"}} >Speciale i børn, unge og familier</h3>

                                <div className="col-md-5">
                                    <div style={{padding: "20px", marginTop: "20px" }}>   
                                        <a target="_blank" href="https://system.easypractice.net/book/psykolog-nina-kristensen" className="button">Book aftale</a>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div style={{padding: "20px", marginTop: "20px" }}>   
                                        <a href="/forloeb/coolkids" className="button">
                                            NYT COOL KIDS FORLØB
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div className="strip strip5">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="single-content">
                            <a href="tel:71901586">
                                <div className="icon">
                                    <i className="flaticon1-smartphone-call"></i>
                                </div>
                                <div className="text">
                                    <h4>Telefon</h4>
                                    <p className="font-24">71 90 15 86</p>
                                </div>
                                <div className="line"></div>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="single-content">
                            <a href="mailto:mail@nina-kristensen.dk">        
                                <div className="icon">
                                    <i className="flaticon1-envelope fi-rr-envelope"></i>
                                </div>
                                <div className="text">
                                    <h4>send en email</h4>
                                    <p>mail@nina-kristensen.dk</p>
                                </div>
                                <div className="line"></div>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="single-content">
                            <a href="https://www.google.com/maps/place/Fan%C3%B8vej+10E,+8382+Hinnerup/" target={"_blank"}>
                                <div className="icon">
                                    <i className="flaticon2-placeholder"></i>
                                </div>
                                <div className="text">
                                    <h4>Lokation</h4>
                                    <p>Fanøvej 10e, <span style={{whiteSpace: "nowrap"}}>8382 Hinnerup</span></p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="services5">
            <div className="contentPadding">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="contentTitle normall">
                                <h2 className="h2 as"><span>I min klinik</span> tilbyder jeg</h2>
                            </div>
                            <div className="emptySpace50 emptySpace-xs30"></div>
                        </div>
                    </div>

                    <div className="row clearFix">
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <div className="tumbWrapper">
                                <a href="/undersoegelser" className="imgWrapper imgTumb bgShadow light">
                                    <img alt="" src="/img/tumbs/assesment-1.jpg"/>
                                </a>
                                <h6 className="h6 as"><a href="/undersoegelser">UNDERSØGELSE</a></h6>
                                <div className='tumbContent small'>
                                    <p>Intelligenstestning og udredning af børn og unge.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <div className="tumbWrapper">
                                <a href="/behandlinger" className="imgWrapper imgTumb bgShadow light">
                                    <img alt="" src="/img/tumbs/treatment-1.jpg"/>
                                </a>
                                <h6 className="h6 as"><a href="/behandlinger">BEHANDLING</a></h6>
                                <div className='tumbContent small'>
                                    <p>Behandlingsforløb til børn, unge og familier.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <div className="tumbWrapper">
                                <a href="/supervision" className="imgWrapper imgTumb bgShadow light">
                                    <img alt="" src="/img/tumbs/supervision-1.jpg"/>
                                </a>
                                <h6 className="h6 as"><a href="/supervision">SUPERVISION</a></h6>
                                <div className='tumbContent small'>
                                    <p>Supervision til psykologer på vej mod autorisation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <div className="tumbWrapper">
                                <a href="/foredrag" className="imgWrapper imgTumb bgShadow light">
                                    <img alt="" src="/img/tumbs/course-1.jpg"/>
                                </a>
                                <h6 className="h6 as"><a href="/foredrag">FOREDRAG</a></h6>
                                <div className='tumbContent small'>
                                    <p>Skræddersyet foredrag</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        
    </>

}

export default Home;