import {useState} from "react";

const BookAppointment = () => {
    return <>
       <div className="contactsAdviceWrapper normall">
            <h4 className="h4 as">Gratis indledende samtale</h4>
            <p>Har du spørgsmål, eller har du brug for vejledning om, hvad der passer bedst til dig? Jeg tilbyder 15 minutters gratis indledende telefonsamtale.</p>
            <div className="emptySpace70 emptySpace-xs30"></div>
            <div className="contactsAdvice">
                <h3 className="h3 as">Gratis indledende samtale</h3>
                <a target="_blank" href="https://system.easypractice.net/book/psykolog-nina-kristensen" className="button">Book aftale</a>
            </div>
        </div>
    </>
}
export default BookAppointment;

