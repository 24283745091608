const ImageThumb = ({headerText, imageUrl, link, children}) => {
    return <>
        <div className="tumbWrapper">
            <a href={link} className="imgWrapper imgTumb bgShadow light">
                <img src={imageUrl} alt=""/>
            </a>
            <h6 className="h6 as"><a href={link}>{headerText}</a></h6>
            <div className="tumbContent small">
                <p>{children}</p>
            </div>
        </div>
        <div className="emptySpace90 emptySpace-md30"></div>
    </>
}
export default ImageThumb;

