import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const Testimonials = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Anbefalinger</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                        style={{ marginLeft: 10, marginRight: 10 }}
                                        icon={faAngleRight} />
                                    <span>Anbefalinger</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contentPadding">
			<div class="container">
				<div class="row">
					<div class="col-xs-12">
						<div class="testimonialsBlock">
							<div class="imgWrapper">
								<img src="img/avatar_man_1.png" alt=""/>
							</div>
							<div class="personeInfo large">
								<span>
									Hurtigt i behandlingen fandt Nina hovedårsagerne til angsten gennem gode samtaler.
								</span>
								<p>Mand, 49 år (klient)</p>
							</div>
							<div class="emptySpace50 emptySpace-xs30"></div>
							<div class="simple-article normall">
								<p>
									Jeg gik til Nina for at få hjælp til at bearbejde angstanfald der opstod i pressede situationer. Hurtigt i behandlingen fandt Nina hovedårsagerne til angsten gennem gode samtaler.
									Nina har givet mig gode modeller og værktøjer til at håndtere og forstå Angst. Behandlingen har givet mig mod på at arbejde med egen udvikling samt at ture at udfordre mig selv forhold til angst.
									Under behandlingen har jeg gradvis fået min selvtillid tilbage ved at arbejde med konkrete øvelser for at udfordre mig i situationer der tidligere kunne have fremprovokeret angstanfald.
									Mentalt har jeg flyttet mig i en positiv retning og føler mig mere robust til at håndtere fremtidige pressede situationer.
									Jeg er dybt taknemlig for min behandling hos Nina og vil klart anbefale hende til andre der skal have professionel og kompetent hjælp i tilsvarende situationer.
								</p>
							</div>
						</div>
						<div class="testimonialsBlock">
							<div class="imgWrapper">
								<img src="img/avatar_female_1.png" alt=""/>
							</div>
							<div class="personeInfo large">
								<span>Samtaler hos Nina har været en kæmpe hjælp, jeg har følt mig hørt og i den grad forstået.</span>
								<p>Kvinde, 50 år (klient)</p>
							</div>
							<div class="emptySpace50 emptySpace-xs30"></div>
							<div class="simple-article normall">
								<p>
								Samtaler hos Nina har været en kæmpe hjælp, jeg har følt mig hørt og i den grad forstået.
								Nærmest fra første minut har jeg følt mig tryg og  Nina har med sin blide, smilende og omsorgsfulde måde, givet mig værktøjer til, at håndtere opstart på job efter en sygemelding. 
								Kan kun give de varmeste anbefalinger
								</p>
							</div>
						</div>
						<div class="testimonialsBlock">
							<div class="imgWrapper">
								<img src="img/avatar_girl_1.png" alt=""/>
							</div>
							<div class="personeInfo large">
								<span>Nu bruger jeg næsten hendes metoder hver dag</span>
								<p>Pige, 10 år (klient)</p>
							</div>
							<div class="emptySpace50 emptySpace-xs30"></div>
							<div class="simple-article normall">
								<p>
									Jeg er en pige på 10 år. Før jeg begyndte på et Coolkids soloforløb hos Nina, var der mange ting jeg var bange for og nervøs over. Jeg var rigtig glad for at komme der og følte mig tryg ved at snakke med hende.
									Jeg troede at det ville blive svært at slippe af med frygten, men med Ninas gode hjælp og metoder var det faktisk nemt! Nu bruger jeg næsten hendes metoder hver dag.
									Da det var sidste gang var jeg næsten ked af at skulle sige farvel til Nina og Coolkids. Jeg vil gerne anbefale hende for alle med angst og frygt. Hun kan altid hjælpe.
									⭐⭐⭐⭐⭐
								</p>
							</div>
						</div>
                        <div class="testimonialsBlock">
							<div class="imgWrapper">
								<img src="img/avatar_female_1.png" alt=""/>
							</div>
							<div class="personeInfo large">
								<span>Nina så alt det i mellem linjerne, jeg havde svært ved at sætte ord på selv</span>
								<p>Kvinde, 27 år (klient)</p>
							</div>
							<div class="emptySpace50 emptySpace-xs30"></div>
							<div class="simple-article normall">
								<p>Jeg har haft et forløb hos Nina af 5 gange, jeg kom og var usikker i mit moderskab og sad som gravid og ventede barn nr. 2. Med et 6 måneders kolik barn i bagagen, sad der en dyb frygt i mig for kommende barn, da jeg stadig et år efter skrigeriet havde svært ved at rumme gråd. Nina forsikrede mig ved hver samtale om alt hvad jeg havde opnået og gjort godt, og var en kæmpe støtte til en skrøbelig mor. Nina så alt det i mellem linjerne, jeg havde svært ved at sætte ord på selv. Nu sidder jeg her et halvt år efter med min baby i armene og har en kæmpe tiltro til mig selv og mit moderskab. Jeg lytter til mig selv og mine grænser, og jeg tør at spørge om hjælp. Jeg fik mange brugbare redskaber til at håndtere gråd og konflikter, som jeg dagligt gør brug af, både overfor min 2 årige og min baby. Jeg tør godt sige at jeg ikke havde stået så sikkert, som jeg gør nu.</p>
							</div>
						</div>
                        <div class="testimonialsBlock">
							<div class="imgWrapper">
								<img src="img/avatar_female_1.png" alt=""/>
							</div>
							<div class="personeInfo large">
								<span>Tak Nina, for at åbne mine øjne for at der også gerne må være plads til mig</span>
								<p>Kvinde, 44 år (klient)</p>
							</div>
							<div class="emptySpace50 emptySpace-xs30"></div>
							<div class="simple-article normall">
								<p>De sidste par måneder har jeg haft fornøjelsen af at følge et online forløb hos Nina. Jeg har fået nogle redskaber som ikke kun har hjulpet mig undervejs, men som også fremover vil hjælpe mig. Tak Nina, for at åbne mine øjne for at der også gerne må være plads til mig.</p>
							</div>
						</div>
						<div class="testimonialsBlock">
							<div class="imgWrapper">
								<img src="img/avatar_female_1.png" alt=""/>
							</div>
							<div class="personeInfo large">
								<span>Nina er kompetent og har gennemslagskraft</span>
								<p>Emma (supervisand)</p>
							</div>
							<div class="emptySpace50 emptySpace-xs30"></div>
							<div class="simple-article normall">
								<p>Nina er god til at se udviklingspotentiale og arbejde videre på det i et konstruktivt samarbejde. Hun har en positiv arbejdsstil, og man føler sig set og hørt både som menneske og som fagperson i hendes selskab. Nina er en, man gerne vil åbne sig op til, og hun har et imødekommende væsen. Nina er kompetent og har gennemslagskraft.</p>
							</div>
						</div>
                        <div class="testimonialsBlock">
							<div class="imgWrapper">
								<img src="img/avatar_female_1.png" alt=""/>
							</div>
							<div class="personeInfo large">
								<span>Nina er god til at se udviklingspotentiale</span>
								<p>Kirstine (supervisand)</p>
							</div>
							<div class="emptySpace50 emptySpace-xs30"></div>
							<div class="simple-article normall">
								<p>Jeg har været glad for at have Nina som vejleder og supervisor under mit praktikforløb. Jeg oplevede Nina som fagligt velfunderet, venlig og struktureret, hvilket gjorde forløbet til en god og tryg oplevelse. Nina var god til at identificere mine ressourcer og bringe mine egne refleksioner i spil som en del af min kompetenceudvikling.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

    </>;
}
export default Testimonials;