import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const DepressionTreatment = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Depression</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Depression</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Depression hos børn og unge</h5>
                                        <p>Depression er en alvorlig mental lidelse, som kan påvirke mennesker i alle aldre. Det kan være særligt udfordrende for børn og unge, da de ofte har sværere ved at udtrykke deres følelser og få den nødvendige hjælp. Det er derfor vigtigt, at vi som samfund og som forældre er opmærksomme på tegn på depression hos børn og unge, og at vi sørger for at give dem den bedst mulige behandling. Hvis dit barn – eller hvis du som ung oplever dig meget trist, er du velkommen til at kontakte mig, så vi sammen kan finde ud af, hvad der kan hjælpe dit barn eller dig til at få et godt og meningsfuldt liv.</p>
                                    </div>
                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default DepressionTreatment;