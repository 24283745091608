import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/BookAppointment/BookAppointment";

const Coolkids = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Cool kids/Chilled</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/behandlinger">behandling</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Cool kids/Chilled</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Cool kids/Chilled</h5>
                                        <p>Jeg er certificeret Cool Kids/Chilled behandler og har arbejdet med ængstelige børn og unge i over 10 år. Da programmet kom til Danmark var jeg som psykologistuderende med til at afprøve det i et dansk format, hvor programmet viste rigtig gode resultater. Jeg har siden hen arbejdet med programmet i flere sammenhænge og set, hvor meget programmet har hjulpet familierne.</p>
                                        <p>Cool Kids/Chilled er et angstbehandlingsforløb til børn og unge i alderen 7-12 år (Cool Kids) og 13-17 år (Chilled). Forældrene deltager også i behandlingen.</p>
                                        <p>
                                            Programmet indeholder 10 sessioner og strækker sig over minimum 10 uger. I Cool Kids/Chilled lærer børn, unge og deres forældre, hvordan de kan arbejde med angsten. Behandlingen kan både foregå i individuelt format og i gruppeformat.<br/>
                                            Behandlingen er baseret på kognitiv adfærdsterapi og de overordnede temaer er:
                                            <div className="simple-article-text-box-wrapper">
                                                <ul className="simple-article-bullets split-list">
                                                    <li>Psykoedukation om angst</li>
                                                    <li>Realistisk tænkning</li>
                                                    <li>Forældres håndtering af angst hos børn og unge</li>
                                                    <li>Gradvis eksponering</li>
                                                    <li>Copingstrategier som assertionstræning og problemløsning</li>
                                                </ul>                                        
                                            </div>
                                        </p>
                                        <p>
                                            Cool Kids/Chilled er videnskabeligt funderet og har vist rigtig gode behandlingsresultater. Efter endt behandling er ca. 60% af børnene og de unge fri af deres primære angstdiagnose. Efter 1 år er tallet 75% og på det tidspunkt er 50% fuldstændig fri af alle psykiske lidelser.
                                        </p>
                                        <p>
                                            Har du og dit barn brug for at lære redskaber til at håndtere angsten, så er I meget velkommen til at kontakte mig.
                                        </p>
                                        <div>
                                            <img style={{maxWidth: "500px", margin: "auto"}} src="/img/coolkidscertificeret-CEBU-logo-farve.png"/>
                                        </div>
                                    </div>

                    



                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default Coolkids;