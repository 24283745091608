import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faEnvelope, faLocation, faPhone} from '@fortawesome/free-solid-svg-icons'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import MenuItem from "../components/Menu/MenuItem";
import Plausible from "plausible-tracker";

const Main = () => {

    const location = useLocation();
    const lastHash = useRef('');

    const [menuOpen, setMenuOpen] = useState(false);
    const [openMenuItem, setOpenMenuItem] = useState();


    const toggleMenuItem = (id) => {
        setOpenMenuItem(openMenuItem === id ? 0 : id)
    }

    const closeMenu = () => {
        setOpenMenuItem(0);
        setMenuOpen(false);
    }

    useEffect(() => {
        if (location.hash) {
          lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }
    
        if (lastHash.current && document.getElementById(lastHash.current)) {
          setTimeout(() => {
            document
              .getElementById(lastHash.current)
              ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            lastHash.current = '';
          }, 100);
        }
      }, [location]);

    return <>
        <header className="tt-header header2 header3">
            <div className="header_bottom">
                <div className="container">
                    <div className="responsiveSrollWrapper">
                        <div className="headerTopInfo">
                            <div className="headerTopInfoContaner">
                                <a href="/" className="logo">
                                    <img alt="" src="/img/logo.png"/>
                                </a>
                                <div className={"menuIcon "}
                                     onClick={() => setMenuOpen(!menuOpen)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="top-inner clearfix">
                            <a className="logo" href="/"><img alt="" src="/img/logo.png"/></a>
                        </div>
                        <div className={"responsiveWrapper " + (menuOpen ? "open" : "")}>
                            <div className="navScroll">
                                <nav>
                                    <ul>
                                        <li onClick={() => toggleMenuItem(1)} >
                                            <MenuItem text={"Undersøgelse"} open={openMenuItem === 1}>
                                                <li onClick={() => closeMenu()}><a href={"/undersoegelser/intelligenstests"}>Intelligenstest</a></li>
                                                <li onClick={() => closeMenu()}><a href={"/undersoegelser/udredning"}>Udredning</a></li>
                                            </MenuItem>
                                        </li>

                                        <li onClick={() => toggleMenuItem(2)}>
                                            <MenuItem text={"Behandling"} open={openMenuItem === 2}>
                                                <li onClick={() => closeMenu()}><a href={"/behandlinger/angst"}>Angst</a></li>
                                                <li onClick={() => closeMenu()}><a href={"/behandlinger/coolkids"}>Cool Kids/Chilled</a></li>
                                                <li onClick={() => closeMenu()}><a href={"/behandlinger/adhd"}>ADHD/ADD</a></li>
                                                <li onClick={() => closeMenu()}><a href={"/behandlinger/autisme"}>Autisme</a></li>
                                                <li onClick={() => closeMenu()}><a href={"/behandlinger/depression"}>Depression</a></li>
                                                <li onClick={() => closeMenu()}><a href={"/behandlinger/ensomhed"}>Ensomhed</a></li>
                                                <li onClick={() => closeMenu()}><a href={"/behandlinger/mistrivsel"}>Mistrivsel</a></li>
                                                <li onClick={() => closeMenu()}><a href={"/behandlinger/udfordringerifamilien"}>Udfordringer i familien</a></li>
                                                <li onClick={() => closeMenu()}><a href="/behandlinger/gruppeforloeb">Gruppeforløb</a></li>
                                            </MenuItem>
                                        </li>
                                        <li onClick={() => toggleMenuItem(3)}>
                                            <MenuItem text={"Andre Ydelser"} open={openMenuItem === 3}>
                                                <li onClick={() => closeMenu()}><a href="/supervision">Supervision</a></li>
                                                <li onClick={() => closeMenu()}><a href="/foredrag">Foredrag</a></li>
                                            </MenuItem>
                                        </li>
                                        <li onClick={() => toggleMenuItem(4)}>
                                            <MenuItem text={"Info"} open={openMenuItem === 4}>
                                                <li onClick={() => closeMenu()}><a className="active" href={"/kontakt"}>Kontakt</a></li>
                                                <li onClick={() => closeMenu()}><a className="active" href={"/priser"}>Priser</a></li>
                                            </MenuItem>
                                        </li>
                                        <li onClick={() => toggleMenuItem(5)}>
                                            <MenuItem text={"Om"} open={openMenuItem === 5}>
                                                <li onClick={() => closeMenu()}><a href="/omkring-mig">Nina Kristensen</a></li>
                                                <li onClick={() => closeMenu()}><a href="/anbefalinger">Anbefalinger</a></li>
                                                <li onClick={() => closeMenu()}><a href="/terapeutisk-tilgang">Terapeutisk tilgang</a></li>
                                                <li onClick={() => closeMenu()}><a href="/omkring-klinikken">Klinikken</a></li>
                                            </MenuItem>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="headerClearFix"></div>
        <div onTouchStart={() => closeMenu()} onClick={() => {closeMenu()}}>
            <Outlet/>
        </div>
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-10 col-sm-offset-2 col-md-6 col-md-offset-0">
                        <div className="footerBlock normall">
                            <div className="footerTitle">
                                <p>Links</p>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6 simple-article style2">
                                    <ul>
                                        <li><a href="https://system.easypractice.net/book/psykolog-nina-kristensen">Book tid</a></li>
                                        <li><a href="/undersoegelser">Undersøgelse</a></li>
                                        <li><a href="/behandlinger">Behandling</a></li>
                                        <li><a href="/priser">Priser</a></li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-6 simple-article style2">
                                    <ul>
                                        <li><a href="/omkring-mig">Om Nina Kristensen</a></li>
                                        <li><a href="/supervision">Supervision</a></li>
                                        <li><a href="/foredrag">Foredrag</a></li>
                                        <li><a href="/behandlinger/gruppeforloeb">Gruppeforløb</a></li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="emptySpace-md30"></div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-3 col-md-offset-0">
                        <div className="footerBlock normall">
                            <div className="footerTitle">
                                <p>Kontakt</p>
                            </div>
                            <div className="locationBlock">
                                <a href="https://www.google.com/maps/place/Fan%C3%B8vej+10E,+8382+Hinnerup/" target={"_blank"}>
                                    <img src="/img/location-icon.png"/>
                                    <div className="locationContent">
                                        <span style={{marginLeft: "15px"}}>Fanøvej 10e, 8382 Hinnerup</span>
                                    </div>
                                </a>
                            </div>
                            <div className="footerContants">
                                <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                                <a href="tel:71901586">71 90 15 86</a>
                            </div>
                            <div className="footerContants">
                                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                                <a href="mailto:mail@nina-kristensen.dk">mail@nina-kristensen.dk</a>
                            </div>
                            <div className="footerContants">
                                <div><span style={{marginRight: 20}}><b>CVR:</b></span><span style={{color: "#666666"}}>43761021</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-5 col-sm-offset-1 col-md-3 col-md-offset-0  pull-right">
                        <div className="footerBlock normall">
                            <div style={{marginTop: "50px", color: "#3c4f79", fontWeight: "600"}}>
                                Medlem af
                                <a href="https://www.dp.dk/">
                                    <img src="/img/dp-logo.png" style={{width:250}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="bottomInfo small">
                        <div className="col-xs-12 col-sm-8">
                            <div className="copy">
                                <p>Copyright © Nina Kristensen 2023. CVR: 43761021.  All rights reserved.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4"></div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        </footer>
    </>
};

export default Main;