import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../components/BookAppointment/BookAppointment";

const Assesment = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Udredning</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/undersoegelser">undersøgelser</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Udredning</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Udredning af børn og unge</h5>
                                        <p>Hvis du er i tvivl, om dit barn har en diagnose - eller hvis du som ung selv er i tvivl, om du har en diagnose - så kontakt mig for at høre nærmere om udredningsmulighederne.</p>
                                        <p>Jeg har særlig viden om:<br/>
                                            Angst<br/>
                                            ADHD/ADD<br/>
                                            Autisme<br/>
                                            Depression<br/>
                                        </p>
                                        <p>
                                            Hvis dit barn skal udredes for en diagnose, vil det i de fleste tilfælde kræve en <a href="/undersoegelser/intelligenstests">kognitiv test</a>. I det offentlige system kan der være lang ventetid på en udredning, hvorfor flere og flere vælger at få lavet testen hos en privatpraktiserende psykolog som mig.
                                        </p>
                                        <p>
                                            Hvis det er relevant, kan testen suppleres med beskrivelser og spørgeskemabesvarelser fra barnets netværk, der kan give en indikation af, om barnet har en diagnose.
                                        </p>
                                    </div>
                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default Assesment;