import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

const TherapeuticApproach = () => {
    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Terapeutisk tilgang</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <span>Terapeutisk tilgang</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Terapeutisk tilgang</h5>
                                        <p>Jeg anvender hovedsageligt kognitiv adfærdsterapi men er også inspireret af andre terapiformer såsom systemisk terapi, narrativ terapi og metakognitiv terapi.</p>
                                        <p><b>Kognitiv adfærdsterapi</b> fokuserer på at ændre negative tanker og adfærdsmønstre. Terapien bygger på teorien om, at vores tanker, følelser og adfærd er tæt forbundet, og at ændring af vores tanker kan have en positiv indvirkning på vores følelser og adfærd.</p>
                                        <p><b>Systemisk terapi</b> fokuserer på forholdet mellem en person og de forskellige systemer, de er en del af, såsom familie, parforhold, skole, arbejde eller samfundet som helhed. I systemisk terapi betragter man ikke en persons problemer som isolerede, individuelle problemer, men som en del af et større netværk af relationer og interaktioner.                                     </p>
                                        <p><b>Narrativ terapi</b> fokuserer på at hjælpe individer til at reflektere over deres livshistorier og identitet. Terapien bygger på teorien om, at vores fortællinger om vores liv påvirker vores følelser, tanker og adfærd. I narrativ terapi arbejdes der med at ændre de fortællinger, der påvirker ens liv negativt.</p>
                                        <p><b>Metakognitiv terapi</b> fokuserer på at ændre de metakognitive processer, der påvirker vores tanker og følelser. I terapien arbejdes der med at få ledt opmærksomheden væk fra alle de mange tanker og bekymringer.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default TherapeuticApproach;