import ImageThumb from "../components/ImageThumb/ImageThumb";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import Treatments from "../treatments.json"

const Services = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Behandlinger</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a> <FontAwesomeIcon style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>behandlinger</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row clearFix big">

                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Angst"} imageUrl={"img/tumbs/anxiety1.jpg"}
                                    link={"/behandlinger/adhd"}>
                            Jeg tilbyder behandling af børn og unge med angst
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Coolkids/Chilled"} imageUrl={"img/tumbs/coolkids-1.jpg"}
                                    link={"/behandlinger/coolkids"}>
                            Jeg er certificeret Coolkids/Chilled behandler og tilbyder sådan forhandlingsløb.
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"ADHD/ADD"} imageUrl={"img/tumbs/adhd1.jpg"}
                                    link={"/behandlinger/adhd"}>
                            Jeg tilbyder børnepsykologisk behandling for ADHD, med fokus på færdigheder, koncentration og adfærd.
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Autisme"} imageUrl={"img/tumbs/autism1.jpg"}
                                    link={"/behandlinger/autisme"}>
                            Jeg tilbyder børnepsykologisk behandling for autisme, med fokus på at forstå og håndtere de unikke udfordringer og styrker.
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Depression"} imageUrl={"img/tumbs/depression1.jpg"}
                                    link={"/behandlinger/depression"}>
                            Jeg tilbyder børnepsykologisk behandling for depression, med fokus på følelsesforståelse og sund håndtering af depression.
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Ensomhed"} imageUrl={"img/tumbs/loneliness1.jpg"}
                                    link={"/behandlinger/ensomhed"}>
                            Jeg tilbyder behandling ensomhed, med fokus på at overvinde følelser og styrke selvtilliden.
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Mistrivsel"} imageUrl={"img/tumbs/distress1.jpg"}
                                    link={"/behandlinger/mistrivsel"}>
                            Jeg tilbyder behandling af mistrivsel blandt børn og unge.
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Udfordringer i familien"} imageUrl={"img/tumbs/family-1.jpg"}
                                    link={"/behandlinger/udfordringerifamilien"}>
                            Jeg vil gerne hjælpe jer til at få et mere roligt og afbalanceret hverdagsliv.
                        </ImageThumb>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <ImageThumb headerText={"Gruppeforløb"} imageUrl={"img/tumbs/group-1.jpg"}
                                    link={"/behandlinger/gruppeforloeb"}>
                            I perioder udbyder jeg følgende gruppeforløb.
                        </ImageThumb>
                    </div>                   
                </div>
            </div>
        </div>
    </>;


};

export default Services;